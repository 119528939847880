<template>
  <footer>
    <div class="box_link">
      <a
        v-for="(linkInfo, idx) in linkList"
        :key="idx"
        target="_blank"
        :href="linkInfo.link"
        :class="[linkInfo.class, { before_separator: idx > 0 }]"
      >
        {{ linkInfo.text }}
      </a>
    </div>
    <div class="box_address">
      <div v-for="(addrLine, idx) in addrList" :key="idx" class="line_address">
        <span
          v-for="(addr, childIdx) in addrLine"
          :key="childIdx"
          v-dompurify-html="addr"
          :class="{ before_separator: childIdx > 0 }"
        ></span>
      </div>
    </div>
    <span class="txt_copyright">
      ⓒ NEXON Korea Corporation All Rights Reserved.
    </span>
    <atoms-c-image file-name="logo-nexon" :density="3" />
  </footer>
</template>

<script lang="ts" setup>
const router = useRouter()
const linkList = [
  {
    text: '이용약관',
    link: router.resolve({
      name: 'legal-type',
      params: { type: 'term' },
      query: {
        id: '925'
      }
    }).href
  },
  {
    text: '개인정보처리방침',
    link: router.resolve({
      name: 'legal-type',
      params: { type: 'term' },
      query: {
        id: '932'
      }
    }).href,
    class: 'bold'
  },
  {
    text: '플랫폼 IP사용 가이드',
    link: router.resolve({
      name: 'legal-type',
      params: { type: 'term' },
      query: {
        id: '934'
      }
    }).href
  },
  {
    text: '청소년보호정책',
    link: router.resolve({
      name: 'legal-type',
      params: { type: 'operation' },
      query: {
        id: '933'
      }
    }).href
  },
  {
    text: '플랫폼 이용 가이드라인',
    link: router.resolve({
      name: 'legal-type',
      params: { type: 'operation' },
      query: {
        id: '935'
      }
    }).href
  }
]
const addrList = [
  [
    '㈜넥슨코리아 대표이사 강대현·김정욱 경기도 성남시 분당구 판교로 256번길 7',
    '전화 : <a href="tel:1588-7701">1588-7701</a>',
    '팩스 : 0502-258-8322'
  ],
  [
    '고객지원 : <a href="mailto:help@hellomaple.org">help@hellomaple.org</a>',
    '제휴문의 : <a href="mailto:hellomaple_biz@nexon.co.kr">hellomaple_biz@nexon.co.kr</a>',
    '사업자등록번호 : 220-87-17483호',
    '통신판매업 신고번호 : 제2013-경기성남-1659호'
  ]
]
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 32px 0;
  background-color: #120937;
  color: $white;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.56px;
  .box_link {
    letter-spacing: -0.6px;
    font-size: 15px;
    display: flex;
    gap: 16px;
    .bold {
      font-weight: 700;
    }
  }
  .box_address {
    opacity: 0.5;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .line_address {
      display: flex;
      gap: 16px;
      justify-content: center;
    }
  }
  .before_separator {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      height: 8px;
      width: 1px;
      left: -8px;
      top: calc(50% - 4px);
      background-color: #ffffff;
    }
  }
  .txt_copyright {
    font-weight: 700;
    opacity: 0.3;
    margin-bottom: 24px;
  }
}

@media (max-width: 999px) {
  footer {
    display: flex;
    gap: 12px;
    padding: 32px 20px;
    .box_link {
      flex-wrap: wrap;
      gap: 4px 16px;
      letter-spacing: -0.56px;
      font-size: 14px;
      justify-content: center;
    }
    .box_address {
      max-width: 480px;
      text-align: center;
      gap: 6px;
      .line_address {
        display: flex;
        justify-content: center;
        gap: 2px;
        &:nth-child(1) {
          flex-wrap: wrap;
          .before_separator:nth-child(3) {
            margin-left: 16px;
            &:before {
              display: initial;
            }
          }
        }
        &:nth-child(2) {
          flex-direction: column;
        }
        .before_separator {
          &:before {
            display: none;
          }
        }
      }
    }
    .txt_copyright {
      letter-spacing: -0.48px;
      font-size: 12px;
    }
  }
}
</style>
