/* eslint-disable no-useless-escape */
import urlParser from 'js-video-url-parser/lib/base'
import 'js-video-url-parser/lib/provider/youtube'

// Shorts 영상 지원을 위한 처리 제거 (500 error 대응 방법 필요)
// eslint-disable-next-line no-proto
/* urlParser.plugins.youtube.__proto__.parseVideoUrl = function (url) {
  const match = url.match(
    /(?:(?:v|vi|be|videos|embed|shorts)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i
  )
  if (match) {
    return match[1]
  }
  return undefined
} */

const pendingArr = []
/**
 * url을 검증 하고, 그에 따라 반환 값을 정한다.
 * !!! 트위치 영상 서비스 종료로 인해 트위치 영상 추가 금지, 기존 트위치 영상은 노출 (기존 영상은 운영 쪽에서 직접 삭제하도록 유도할 예정)
 * @param {String} url - 영상 url
 * @returns { id?: string; provider?: string; error?: number; message?: string }
 * 0: 지원하지 않는 형식입니다. (url 유효성)
 * 1: 영상만 가능합니다. (stream type X)
 * 2: 지원하지 않는 사이트입니다. (youtube 외 모두 X)
 */
const VIDEO_URL_PARSER = (url) => {
  const urlInfo = urlParser.parse(url)

  if (urlInfo === undefined) {
    return { error: 0, message: 'n.invalidUrl' }
  }

  if (!(urlInfo.mediaType === 'video' || urlInfo.mediaType === 'clip')) {
    return { error: 1, message: 'n.notSptStreaming' }
  }

  if (urlInfo.provider !== 'youtube') {
    return {
      error: 2,
      message: 'n.notSptVideo'
    }
  }

  return urlInfo
}

/**
 * 부모 Element에게 유튜브 Iframe을 붙인다.
 * @param {String} id - Youtube ID
 * @param {Element} parentEl - 비디오가 붙을 div
 * @param {Boolean} autoplay - 자동 재생 여부
 * @param {Function} callback - iframe 로드 완료 시 호출 함수
 * @param {Boolean=} fullSize - width, height 100% 여부
 * @param {Number=} controls - 비디오 컨트롤 사용 여부 0/1
 * @param {Boolean=} isMute - 음소거 여부
 */
const CREATE_YOUTUBE_ELEMENT = (
  id,
  parentEl,
  autoplay = true,
  callback,
  fullSize = false,
  controls,
  isMute = true
) => {
  if (
    typeof window.YT === 'undefined' ||
    typeof window.YT?.Player !== 'function'
  ) {
    if (!document.getElementById('YoutubeScript')) {
      const tag = document.createElement('script')
      const firstScriptTag = document.getElementsByTagName('script')[0]
      tag.id = 'YoutubeScript'
      tag.src = 'https://www.youtube.com/iframe_api'
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      window.onYouTubeIframeAPIReady = () => {
        while (pendingArr.length > 0) {
          const {
            id,
            parentEl,
            autoplay,
            callback,
            fullSize,
            controls,
            isMute
          } = pendingArr.pop()
          CREATE_YOUTUBE_ELEMENT(
            id,
            parentEl,
            autoplay,
            callback,
            fullSize,
            controls,
            isMute
          )
        }
      }
    }
    if (id) {
      pendingArr.push({
        id,
        parentEl,
        autoplay,
        callback,
        fullSize,
        controls,
        isMute
      })
    }
  } else if (id) {
    const videoEl = document.createElement('div')
    videoEl.setAttribute('id', `videoPlayer-${id}`)
    videoEl.style.maxWidth = '100%'
    // 왜인지 유튜브는 해당 Element부터 붙이고 Player 붙여야함. Twitch랑 다름
    // 통일성을 위해 Twich도 동일하게.
    parentEl.appendChild(videoEl)
    // eslint-disable-next-line no-unused-vars
    window.YTConfig = {
      host: 'https://www.youtube.com/iframe_api'
    }

    const pObject = new window.YT.Player(videoEl, {
      host: 'https://www.youtube.com',
      width: fullSize ? '100%' : 640,
      height: fullSize ? '100%' : 360,
      videoId: id,
      playerVars: {
        origin: window.location.href,
        rel: 0,
        modestbranding: 1,
        controls
      },
      events: {
        onReady: (event) => {
          if (isMute) event.target.mute()
          if (autoplay) event.target.playVideo()
          if (typeof callback === 'function') callback(pObject)
        }
      }
    })
  }
}

export { VIDEO_URL_PARSER, CREATE_YOUTUBE_ELEMENT }
